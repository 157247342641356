import React from "react"
import { graphql } from "gatsby"

import ArticleSig from "../components/article_sig"
import ArticleTitle from "../components/article_title"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionImage from "../components/section_image";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article>
          <ArticleTitle  alt="Mountains" filename="mountains-wide.jpg">
            <header style={{padding: `20px 0`}}>
              <h1>{post.frontmatter.title}</h1>
              <h2>{post.frontmatter.subtitle}</h2>
              <p>{post.frontmatter.date}</p>
            </header>
         </ArticleTitle>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <ArticleSig />
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        subtitle
        author
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
