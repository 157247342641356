import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { rhythm } from "../utils/typography"
import Image from "gatsby-image"

const ArticleSig = () => {
  const data = useStaticQuery(graphql`
    query ArticleSigQuery {
      avatar: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `);

  const { author, social } = data.site.siteMetadata
  return (
    <div style={{
      marginTop: `40px`
    }}>
      <div
        style={{
          display: `flex`,
          marginBottom: rhythm(2.5),
          width: `768px`,
          margin: `0 auto`,
          backgroundColor: `rgba(255, 255, 255, .5)`,
          paddingTop: `1em`,
          paddingLeft: `1em`,
          paddingright: `.5em`,
          borderRadius: `20px`,
          border: `1px solid #ccc`
        }}
      >
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={author}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            minWidth: 50,
            borderRadius: `100%`,
          }}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
        <p>
          Would you like to see more content like this?<br />
          Let me know! <a href={`https://twitter.com/${social.twitter}`}>Follow and share your thoughts on Twitter.</a>
          &nbsp;DMs always open.
        </p>
      </div>
    </div>
  )
};

export default ArticleSig
